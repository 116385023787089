import React, { memo, useEffect } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';
import AuthenticateRoute from 'components/AuthenticateRoute';
import { Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import AppLocale from 'lngProvider';
import MainApp from './MainApp';
import SignIn from 'views/pages/Auth/SignIn';
import UserAgreement from 'views/pages/Public/UserAgreement';
import PrivacyPolicy from 'views/pages/Public/PrivacyPolicy';
import PublicRoute from 'components/PublicRoute';
import { store } from 'store';
import socket from 'helpers/socket';
import useInactivity from 'helpers/useInactivity';
import { permissions, canAccess } from 'helpers/permission';
import {
  getNotificationCount,
  getNotifications,
} from 'store/actions/notification';
import { getCsrSummary } from 'store/actions/csrSummary';
import {
  toggleViewAnnouncementsModal,
  getCurrentUserAnnouncements,
} from 'store/actions/announcement';
import {
  getTimerRunning,
  setClockModal,
  updateTimerExistingShift,
} from 'store/actions/timer';
import { getNATimerRunning } from 'store/actions/nightAssist';

const LIBRARIES = ['geometry', 'places'];

const App = () => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: LIBRARIES,
  });
  const dispatch = useDispatch();
  useInactivity();
  const locale = useSelector(({ preferences }) => get(preferences, 'locale'));
  const currentAppLocale = AppLocale[locale.locale];
  const authToken = useSelector(({ auth }) => auth?.auth_token);

  useEffect(() => {
    // if auth token changes, then call socket start or stop method based on login or logout
    if (authToken) {
      // Reset clock modal open
      ['clockModal', 'confirmClockModal'].map(key => {
        dispatch(
          setClockModal(key, {
            isOpen: false,
            data: {},
          })
        );
        return true;
      });
      // Reset existing timer shift data
      dispatch(updateTimerExistingShift({}));
      // only start socket if we have authToken and we haven't started socket for that user
      // if user changes then start socket again, start socket method automatically stop past socket
      const storeData = store.getState();
      const loggedInUser = get(storeData, 'auth.user', null);
      if (loggedInUser) {
        socket.start({
          ...loggedInUser,
          auth_token: authToken,
        });
      }
      dispatch(getCsrSummary());
      dispatch(
        getNotificationCount(res => {
          dispatch(
            getNotifications({
              limit: res.count < 100 ? 100 : res.count,
            })
          );
        })
      );
      dispatch(
        getCurrentUserAnnouncements(res => {
          const announcements = get(res, 'data') || [];
          if (announcements.length > 0) {
            dispatch(toggleViewAnnouncementsModal(true));
          }
        })
      );
      if (canAccess(permissions.USE_TIMER)) {
        dispatch(getTimerRunning());
        dispatch(getNATimerRunning());
      }
    } else {
      // if there is no auth token means user is logged out then stop listening to socket
      socket.stop();
    }
    return () => {
      dispatch(toggleViewAnnouncementsModal(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken]);

  if (!isLoaded) {
    return <></>;
  }

  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <Switch>
          <PublicRoute exact path="/sign-in" component={SignIn} />
          <PublicRoute
            exact
            path="/user-agreement"
            component={UserAgreement}
            publicView
          />
          <PublicRoute
            exact
            path="/privacy-policy"
            component={PrivacyPolicy}
            publicView
          />
          <AuthenticateRoute component={MainApp} />
        </Switch>
      </IntlProvider>
    </ConfigProvider>
  );
};

export default memo(App);
